// work-sans-regular - latin
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Work Sans'), local('WorkSans-Regular'),
       url('../Fonts/work-sans-v5-latin-regular.woff2') format('woff2'), // Chrome 26+, Opera 23+, Firefox 39+
       url('../Fonts/work-sans-v5-latin-regular.woff') format('woff'); // Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+
}

body {
  @include font;
  @media #{$screen-sm} {
    padding-top: 0px !important;
  }
}

footer {
  .panel {
    margin-bottom: 0;

    .panel-footer {
      padding-top: 30px;
    }

    @media #{$screen-sm} {
      .panel-body,
      .panel-footer {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include font;
  line-height: 1.1;
}

h1 {
  @include font-bold;
  letter-spacing: -2.5px;
}

a {
  color: $brandColorPrimary;
  text-decoration: none;
  @include transition(color, background-color 0.2s);

  &:hover {
    color: $brandColorPrimary;
  }
}

img {
  max-width: 100%;
  height: auto;
}

.row {
  margin-bottom: 2em;
}

.main-header {
  background-color: #141414;
  overflow: hidden;
  height: 100%;
  padding-top: 85px;
  padding-bottom: 115px;
  margin-bottom: 115px;

  &,
  h1 {
    color: #fff;
  }

  &.image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 85px 0 0;
    background-position: 50% 50%;
    background-size: auto 100%;
    background-repeat: no-repeat;
    margin-bottom: 0;

    .container {
      margin-top: calc(33vh + 85px);
      text-align: center;
    }

    h1 {
      text-shadow: 1px 0 0 rgba(0, 0, 0, 0.5);
      letter-spacing: -1px;
    }
  }

  a {
    color: #fff;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  @media #{$screen-sm} {
    h1 {
      font-size: 1.8rem;
    }
  }
}

.main-header.image + .container {
  margin-top: 100vh;
}

// Inline alignments
.neos-alignment-right {
  float: right;
  margin-left: 2em;
  margin-bottom: 1em;
}

.neos-alignment-left {
  float: left;
  margin-right: 2em;
  margin-bottom: 1em;
}
