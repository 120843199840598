.chapter {
  padding-top: 20px;
  background-color: #fafafa;
}

.chapter-container {
  max-width: 640px;
  font-size: 1.3em;
  text-align: justify;

  p:first-of-type {
    $dropcap-font-family: Times;
    $dropcap-margin: 0 10px 2px 0;
    $dropcap-color: $brandColorPrimary;

    @include dropcap($dropcap-float-position, $dropcap-font-size, $dropcap-font-family, $dropcap-txt-indent, $dropcap-margin, $dropcap-padding, $dropcap-color, $dropcap-line-height, $dropcap-bg);
  }

  p {
    margin-bottom: 2em;
  }

  img {
    max-width: 250px;
    height: auto;
    margin: 0 0 0.5em 0.8em;

    @media #{$screen-sm} {
      margin-left: auto;
      margin-right: auto;
      float: none;
      display: block;
      width: 100%;
      max-width: 100%;
    }
  }
}

.chapter-pager {
  padding-left: 0;
  text-align: center;
  font-size: 0.8em;

  li {
    list-style-type: none;
    display: inline;
  }
}
