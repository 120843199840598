.flickr-stream {
  ul {
    padding-left: 0;
  }

  li {
    list-style-type: none;
    width: 240px;
    margin: 0 10px 10px;
    text-align: center;
  }

  img {
    margin-bottom: 10px;
    max-height: 190px;
    max-width: 250px;
  }
}
