ol {
  &.neos-list-decimal {
    list-style-type: decimal;
  }

  &.neos-list-decimal-leading-zero {
    list-style-type: decimal-leading-zero;
  }

  &.neos-list-lower-roman {
    list-style-type: lower-roman;
  }

  &.neos-list-upper-roman {
    list-style-type: upper-roman;
  }

  &.neos-list-lower-greek {
    list-style-type: lower-greek;
  }

  &.neos-list-lower-latin {
    list-style-type: lower-latin;
  }

  &.neos-list-upper-latin {
    list-style-type: upper-latin;
  }
}

ul {
  &.neos-list-disc {
    list-style-type: disc;
  }

  &.neos-list-circle {
    list-style-type: circle;
  }

  &.neos-list-square {
    list-style-type: square;
  }
}
