.responsive-video {
  position: relative;
  padding-bottom: 55%;
  padding-top: 15px;
  margin-bottom: 1.5em;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
