// Wrap for first and second level navigation at the top
.top-navigation-wrap {
  position: sticky;
  background-color: $white;
  background-color: rgba(255, 255, 255, 0.92);
  transition: box-shadow 0.2s linear;
  min-height: 85px;

  &.scrolling {
    @include box-shadow(0 5px 15px rgba(0, 0, 0, 0.15));
  }

  @media #{$screen-sm} {
    position: relative;
    margin-left: -15px;
    margin-right: -15px;

    &,
    &.scrolling {
      @include box-shadow(none);
    }
  }

  // Fix for menu position in neos backend
  .neos-controls & {
    @media #{$screen-sm} {
      top: auto;
    }
  }

  .neos-edit-preview-panel-open:not(.neos-full-screen) & {
    top: 182px;
    @media #{$screen-sm} {
      top: auto;
    }
  }

  .neos-inspector-panel-open:not(.neos-full-screen) & {
    right: 320px;
  }

  .neos-menu-panel-open:not(.neos-full-screen) &,
  .neos-navigate-panel-open:not(.neos-full-screen) & {
    left: 320px;
  }
}

// First level top navigation which can be toggled on small devices
.first-level-navigation {
  margin-top: 25px;
  margin-bottom: 0;

  a {
    font-size: 1.3em;
  }

  @media #{$screen-sm} {
    .nav-pills > li {
      float: none;
      margin-right: 0px;
    }
  }
}

// Second level navigation which is contained in the first level navigation and shown for small devices
.second-level-navigation {
  margin-left: 155px;
  margin-bottom: 0;
  min-height: 40px;
  padding-bottom: 8px;

  @media #{$screen-sm} {
    display: none;
  }

  .nav-pills > li {
    margin-right: 30px;

    & > a {
      font-size: 12px;
      line-height: 20px;
      @include font;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

// Neos logo
.navbar-brandlogo {
  width: 50px;
  height: 50px;
  margin-right: 30px;
  margin-top: -3px;
}

@media only screen and (max-width: 767px) {
  .navbar-brandlogo {
    margin-top: -9px;
  }
}

.navbar-default {
  background-color: transparent;
  border: none;

  .navbar-header {
    padding-bottom: 18px;
  }

  .navbar-toggle {
    background-color: $brandColorPrimary;
    outline: none;
    margin-top: 0;

    .icon-bar {
      background-color: $white;
    }
  }
}

// The bootstrap navbar has a height limit we don't want
.container .navbar-collapse {
  max-height: none;
  padding: 0;
  margin: 0;
}

@media #{$screen-sm} {
  .navbar-collapse {
    border-bottom: 1px solid transparent;
  }
}

.nav-pills > li {
  display: block;
  float: left;
  width: auto;
  margin-right: 40px;

  & + li {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  & > a {
    color: $textSubtle;
    font-size: 14px;
    line-height: 46px;
    @include font-bold;
    vertical-align: bottom;
    @include border-radius(0);
    padding: 0;
    text-decoration: none;

    &:hover {
      background-color: transparent;
      color: $grayDarker;
    }
  }

  &.active > a,
  &.current > a {
    &,
    &:hover,
    &:focus {
      color: $brandColorPrimary;
      background-color: transparent;
      @include border-radius(0);
    }
  }

  li {
    & > a {
      color: $textSubtle;
      font-size: 14px;
      @include font;

      &:hover {
        background-color: transparent;
        color: $grayDarker;
      }
    }

    &.active > a,
    &.current > a {
      &,
      &:hover,
      &:focus {
        color: $brandColorPrimary;
        background-color: transparent;
        @include border-radius(0);
      }
    }
  }
}

// The caret for dropdown menus
.nav {
  .caret {
    border-top-color: $grayMedium;
    border-bottom-color: $grayMedium;
  }

  .active .caret,
  .current .caret {
    border-top-color: $white;
    border-bottom-color: $white;
  }
}

// Second level navigation shown for bigger devices
.nav-tabs {
  border-bottom: none;

  & > li {
    margin-right: 55px;
    margin-bottom: 5px;

    & > a {
      padding: 0;
      text-decoration: none;
      border: none;

      &:hover {
        background-color: transparent;
        color: $grayDarker;
      }
    }

    &.current > a {
      color: $textSubtle;
      cursor: default;
      background-color: transparent;

      &,
      &:hover {
        color: $brandColorPrimary;
      }
    }
  }
}

// Footer navigation
footer.navbar {
  margin-top: 20px;
  margin-bottom: 0;
}

.language-menu {
  float: right;
  margin-top: 25px;
  font-size: 14px;
  line-height: 46px;
  position: relative;
  z-index: 1001;
  cursor: pointer;
  @include font-bold;

  div {
    .language-menu-full {
      font-size: 14px;
    }

    .language-menu-short {
      display: none;
    }

    span {
      font-size: 10px;
    }

    @media #{$screen-sm} {
      width: 44px;
      height: 34px;
      text-align: center;
      line-height: 30px;
      text-transform: uppercase;
      margin-right: 15px;
      background-color: $brandColorPrimary;
      color: #fff;
      border: 1px solid #ddd;
      border-radius: 4px;
      margin-bottom: 8px;

      .language-menu-full {
        display: none;
      }

      .language-menu-short {
        display: inline;
      }
    }
  }

  &:hover {
    ul {
      display: block;
    }
  }

  ul {
    display: none;
    margin: 0;
    padding-left: 0;
    position: absolute;
    background-color: #fff;
    box-shadow: 0 0 2px #000;
    width: 100px;

    @media #{$screen-sm} {
      width: 42px;
      margin: 0 1px;
      text-align: center;
    }

    &:before,
    &:after {
      content: "";
      width: 10px;
      height: 10px;
      position: absolute;
      top: -5px;
      left: 50%;
      margin-left: -5px;
      background-color: #fff;
    }

    &:before {
      box-shadow: 0 0 2px #000;
      @include transform(rotate(45deg));
    }

    &:after {
      top: 0;
      width: 20px;
      margin-left: -10px;
    }

    li {
      list-style: none;
      padding: 0 10px;
      line-height: 20px;
      margin-bottom: 10px;
      @include font;
      font-size: 13px;
      color: $textSubtle;

      &:first-child {
        margin-top: 10px;
      }

      &.current a {
        color: $brandColorSecondary;
        text-decoration: underline;
      }

      a {
        color: $brandColorPrimary;
      }

      .language-menu-short {
        display: none;
      }

      @media #{$screen-sm} {
        .language-menu-full {
          display: none;
        }

        .language-menu-short {
          display: inline;
          text-transform: uppercase;
        }
      }
    }
  }
}
